import request from '../../utils/request'

interface GetProgram {
  size: number;
  current: number;
  [propName: string]: any;
}
//获取评估标准接口分页
export function getProgramList(data: GetProgram): Promise<any> {
  return request({
    method: 'GET',
    type: 'params',
    data: data,
    url: 'manage/program/list'
  })
}

interface Surplus {
  type: string;
  id?: string | number;
}
//查询剩余未健康测评的体检数据
export function getSurplusList(data: Surplus): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/program/regist/surplus/' + data.type,
    data: { id: data.id },
    type: 'params',
  })
}

//上传图片
export function uploadImg(file: FormData) {
  return request({
    url: 'manage/manage/upload?uploadPath=/healthy',
    method: 'POST',
    data: file
  })
}

interface Program {
  content: string;
  title: string;
  reportRegistId: string;
  id?: string | number | null;
}
// 新增方案管理接口
export function postProgram(data: Program) {
  return request({
    url: 'manage/program/submit',
    method: 'POST',
    data: data
  })
}

// 获取详情某条方案接口
export function getDetailProgram(id: number) {
  return request({
    url: 'manage/program/detail/' + id,
    method: 'GET'
  })
}

// 删除某个方案接口
export function deleteDetailProgram(ids: number) {
  return request({
    method: 'POST',
    url: 'manage/program/remove?ids=' + ids
  })
}


//根据checkedid获取检查项
export function selectcheckedid(ids: number){

  return request({
    method:"GET",
    url:"manage/program/regist/surplusByCheckerId?checkerId="+ids
  })

}